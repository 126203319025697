angular.module('app')
    .directive("loadingRow", function () {
        return {
            restrict: "A",
            scope: {
                loadingRow: '='
            },
            template: '<i class="fa fa-spin fa-circle-o-notch"></i>',
            link: function(scope, element, attrs){
            	scope.$watch('loadingRow', function(newVal, oldVal){
            		element.css("display", newVal ? "block" : "none");
            	});
            }
        };
    });
